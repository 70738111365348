import { DEFAULT_EXPERIMENT_MODE } from './constants.js';

export const getStationCode =  () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('stationCode');
};


export const getIsTest = () => {
  const params = new URLSearchParams(window.location.search);
  const isTest = params.get('isTest');
  return isTest !== null && isTest === 'true';
};

export const getExperimentMode = () => {
  const params = new URLSearchParams(window.location.search);
  return getIsTest() ? params.get('experimentMode') : null;
};

export const getTabKey = () => {
  return `ShiftPlan ${getStationCode()} Tab`;
};

export const getTabLastSelected = () => {
  return window.localStorage.getItem(getTabKey());
};

export const setTabLastSelected = (tab) => {
  return window.localStorage.setItem(getTabKey(), tab);
};

export const promiseWithTimeout = (promise, timeoutMs, errorMessage) => {
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error(errorMessage || `Operation timed out after ${timeoutMs}ms`));
    }, timeoutMs);
  });

  return Promise.race([promise, timeoutPromise]);
};