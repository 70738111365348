import { cloneDeep } from 'lodash';
import { nameFormatter } from '../shiftRotation/utils';
import { alphabeticalSort, getRegion } from '../utils/helpers';
import { getUpdatedProcessInfoWithAssociatesToFiveSProcessPath } from "./view/utils";

export const DEFAULT_SHIFT_ALLOCATION_MODEL = {
  associateInfo: {},
  processInfo: [],
  availableCycles: [],
  availableShifts: [],
  boardEntries: []
};

export const CLUSTER_TYPES = {
  CLUSTER: {
    type: 'SORT_CLUSTER',
    label: 'Cluster',
    isADTA: false
  },
  ADTA: {
    type: 'ADTA_CLUSTER',
    label: 'ADTA',
    isADTA: true
  }
};

export const EMPLOYEE_ICON_COLORS = {
  CLOCKED_IN: '#348118',
  CLOCKED_OUT: '#6E777F',
  RECOMMENDED: '#0085b3',
  ISSUE: '#AD0A30'
};

export const EXCLUDED_FROM_VIOLATIONS = [
  'Trainee',
  'Training Ambassador',
  'Stow Bag Replenishment',
  'OPS',
  'Learning Coordinator',
  'Non Scan Stow'
];

export const PROCESS_PATHS = {
  INDUCT: 'Induct',
  STOW: 'Stow',
  PICK_TO_BUFFER: 'Pick to Buffer',
  PICK: 'Pick',
  STAGE: 'Stage',
  TRAINEE: 'Trainee',
  ASL: 'ASL',
  DIVERTER: 'Diverter',
  AUTO_DIVERT_STRAIGHTENER: 'Auto Divert Straightener',
  INBOUND_DOCK: 'Inbound Dock W/S',
  SORT_PS: 'Sort Problem Solve',
  BENCH: 'Bench',
  ADTA_STOW: 'ADTA Container Building',
  Pusher: 'Pusher',
  UTR_OPS_SUPERVISOR: 'UTR OPS Supervisor / SA',
  NON_CON_MANUAL_HANDLING: 'Non-Con Manual Handling',
  NON_SCAN_STOW: 'Non-Scan Stow',
  YARD_MARSHALL: 'Yard Marshall',
  INDUCT_LINE_LOADER: 'Induct Line Loader',
  FIVE_S: '5S'
};

function RingStatus(color, lineType) {
  this.color = color;
  this.lineType = lineType;
}
export function ThumbnailBadge(color, text) {
  this.color = color;
  this.text = text;
}

function Associate(
  alias,
  name,
  img,
  trainedPaths,
  recommendedPaths,
  disallowedPaths,
  ppr,
  ringStatus,
  clockedInStatus,
  associateId,
  thumbnailBadge,
  trackingPath
) {
  this.alias = alias;
  this.name = name;
  this.img = img;
  this.trainedPaths = trainedPaths;
  this.recommendedPaths = recommendedPaths;
  this.disallowedPaths = disallowedPaths;
  this.ppr = ppr;
  this.ringStatus = ringStatus;
  this.clockedInStatus = clockedInStatus;
  this.associateId = associateId;
  this.thumbnailBadge = thumbnailBadge;
}

export const ALLOCATION_TYPE = {
  PROCESS: 'PROCESS',
  TENURE: 'TENURE',
  AISLES: 'AISLES',
  CLUSTERS: 'CLUSTERS'
};

export const PROCESS_TYPE = {
  INDUCT_LINE: 'INDUCT_LINE',
  ASL_LINE: 'ASL_LINE',
  GROUP: 'PROCESS_GROUP',
  PROCESS: 'PROCESS',
  SORT_CLUSTER: 'SORT_CLUSTER',
  ADTA_CLUSTER: 'ADTA_CLUSTER',
  TRAINING: 'TRAINING_GROUP'
};

export const ALLOCATION_ALLOWED_STATION_CODES = [
  'DAU5',
  'DWA6',
  'DLX5',
  'DIL7',
  'DML4',
  'DML8',
  'DWI4',
  'DAT6',
  'DGE9',
  'DTP9',
  'DFT4',
  'DFL5',
  'DWS4',
  'DBI4',
  'DBI7',
  'DDN1',
  'DLU2',
  'DRM4',
  'DSS2',
  'DDD1',
  'DAU2',
  'DAU7',
  'DWA5',
  'DDX7',
  'DDX2',
  'DDX6',
  'DDT9',
  'DRT8',
  'DBM5',
  'DCL7',
  'DWA2',
  'DSW3',
  'DAB4',
  'DAB5',
  'DAS8',
  'DAT9',
  'DAX5',
  'DAZ2',
  'DBK1',
  'DBL1',
  'DBU2',
  'DFH7',
  'DGE4',
  'DGE7',
  'DGI3',
  'DGR3',
  'DGT2',
  'DHT4',
  'DHX1',
  'DIA5',
  'DID3',
  'DII3',
  'DIL3',
  'DIN4',
  'DIN6',
  'DIN8',
  'DJE9',
  'DJR1',
  'DJR5',
  'DJT6',
  'DKO1',
  'DKY4',
  'DKY6',
  'DKY8',
  'DLA3',
  'DLI5',
  'DLI9',
  'DLN4',
  'DLR2',
  'DLT6',
  'DLX7',
  'DMD2',
  'DMD4',
  'DMD5',
  'DMD6',
  'DMF3',
  'DMI7',
  'DMI9',
  'DMO6',
  'DMS2',
  'DNJ3',
  'DOI3',
  'DOI6',
  'DOK6',
  'DOM2',
  'DOR3',
  'DPD2',
  'DPD7',
  'DPD8',
  'DPH8',
  'DPH9',
  'DPP1',
  'DPP7',
  'DPX4',
  'DRT7',
  'DSK4',
  'DSX9',
  'DTG5',
  'DVA2',
  'DVB4',
  'DVB5',
  'DVB7',
  'DVB8',
  'DVC4',
  'DVV5',
  'DVY2',
  'DWO1',
  'DWO6',
  'DXH5',
  'DXT5',
  'DXT6',
  'DXY4',
  'DYB2',
  'DYO5',
  'DYY3',
  'DYY4',
  'DYY5',
  'DYY6',
  'DYY8',
  'DAB8',
  'DAE1',
  'DAE3',
  'DAE7',
  'DAS7',
  'DAS9',
  'DAU1',
  'DAX3',
  'DAX7',
  'DAX8',
  'DAZ4',
  'DBA5',
  'DBA7',
  'DBA8',
  'DBC3',
  'DBK4',
  'DBL8',
  'DBM3',
  'DBO6',
  'DBO7',
  'DBO9',
  'DBU1',
  'DBU3',
  'DBU7',
  'DBU9',
  'DCB4',
  'DCD6',
  'DCH6',
  'DCH8',
  'DCK1',
  'DCK6',
  'DCL2',
  'DCL3',
  'DCL4',
  'DCL9',
  'DCM5',
  'DCN2',
  'DCS3',
  'DCW8',
  'DCX2',
  'DCX5',
  'DCX7',
  'DCX8',
  'DJC5',
  'DCY1',
  'DCY9',
  'DDC3',
  'DDC9',
  'DDE9',
  'DDF1',
  'DDF4',
  'DDF5',
  'DDO6',
  'DDP3',
  'DDP7',
  'DDT1',
  'DDT3',
  'DDT4',
  'DDV3',
  'DDV4',
  'DDW1',
  'DEB2',
  'DFA5',
  'DFH3',
  'DFL3',
  'DFL4',
  'DFL7',
  'DFL8',
  'DFM3',
  'DFM4',
  'DFM5',
  'DFO2',
  'DFO3',
  'DFO9',
  'DFX4',
  'DFX9',
  'DGR6',
  'DGR8',
  'DGT8',
  'DHI2',
  'DHO3',
  'DHO7',
  'DHO8',
  'DHT1',
  'DHX3',
  'DHX4',
  'DIA3',
  'DIA4',
  'DIB5',
  'DIB6',
  'DIB7',
  'DID2',
  'DII4',
  'DIL5',
  'DIN3',
  'DJE1',
  'DJE2',
  'DJE3',
  'DJE5',
  'DJR3',
  'DJW8',
  'DJX2',
  'DJX3',
  'DJX4',
  'DJZ2',
  'DJZ3',
  'DJZ4',
  'DJZ5',
  'DJZ6',
  'DJZ8',
  'DKC3',
  'DKO9',
  'DKS3',
  'DKY5',
  'DKY9',
  'DLA8',
  'DLB2',
  'DLD1',
  'DLD7',
  'DLF1',
  'DLI3',
  'DLI4',
  'DLI6',
  'DLI8',
  'DLN2',
  'DLN3',
  'DLN8',
  'DLT2',
  'DLT3',
  'DLT7',
  'DLT8',
  'DLV2',
  'DLV3',
  'DLV4',
  'DLV7',
  'DLX8',
  'DLX9',
  'DMC3',
  'DMC4',
  'DMD8',
  'DMD9',
  'DMF1',
  'DMF5',
  'DMF8',
  'DMH4',
  'DMH9',
  'DML2',
  'DML3',
  'DMO3',
  'DMO4',
  'DMP1',
  'DMS6',
  'DNA4',
  'DNA6',
  'DNH2',
  'DNJ2',
  'DNJ4',
  'DNJ7',
  'DNK2',
  'DNK5',
  'DNK7',
  'DNO2',
  'DOB2',
  'DOB4',
  'DOB7',
  'DOK2',
  'DOK3',
  'DOK4',
  'DOR2',
  'DOT4',
  'DPD4',
  'DPD6',
  'DPH7',
  'DPL2',
  'DPL7',
  'DPS1',
  'DPS2',
  'DPS5',
  'DPS6',
  'DPX7',
  'DRC6',
  'DRI1',
  'DRO2',
  'DRT3',
  'DRT4',
  'DRT9',
  'DSC3',
  'DSC4',
  'DSD1',
  'DSD4',
  'DSD5',
  'DSD8',
  'DSE8',
  'DSF5',
  'DSF7',
  'DSF8',
  'DSJ5',
  'DSJ9',
  'DSM4',
  'DSR2',
  'DSR4',
  'DSR6',
  'DSW5',
  'DSX5',
  'DSX8',
  'DTB2',
  'DTB9',
  'DTN6',
  'DTN7',
  'DTN8',
  'DTP3',
  'DTP7',
  'DTU2',
  'DTU3',
  'DTU6',
  'DTU7',
  'DTU8',
  'DTU9',
  'DUR1',
  'DUR3',
  'DUR9',
  'DUT2',
  'DUT3',
  'DUT4',
  'DUT7',
  'DVA3',
  'DVA5',
  'DWA7',
  'DWA9',
  'DWS5',
  'DXC3',
  'DXC5',
  'DXC8',
  'DXH6',
  'DYN5',
  'DYN7',
  'DYN9',
  'DYO1',
  'DYO2',
  'DYR3',
  'DYR7',
  'DYY9',
  'DXX5',
  'DTB4',
  'DWD6',
  'DXX4',
  'DCG4',
  'DDV5',
  'DBK6',
  'DCB8',
  'DCL5',
  'DCM2',
  'DCM3',
  'DCM6',
  'DDA9',
  'DDC4',
  'DDE8',
  'DDF2',
  'DDP1',
  'DDP5',
  'DDP9',
  'DDT6',
  'DDV2',
  'DDV5',
  'DDW7',
  'DEW5',
  'DFH1',
  'DFL1',
  'DLC1',
  'DLC8',
  'DTO3',
  'DTY4',
  'DYT6',
  'DYN3',
  'WTN1'];

export const MAX_CLUSTER_CHUNK_SIZE = 5;

export const EMPTY_PROCESS = 'EMPTY_PROCESS';

export const TIME_WINDOW_PHASES = {
  INDUCT_AND_STOW: 'INDUCT_AND_STOW',
  PICK_AND_STAGE: 'PICK_AND_STAGE'
};

export const processAvailableCycles = (timeWindowAllocations) => {
  const result = new Set();

  timeWindowAllocations.forEach((timeWindowAllocation) => {
    result.add(timeWindowAllocation.timeWindowAttrs.find((attr) => !!attr.cycle).cycle);
  });

  return [...result];
};

export const processAvailableShifts = (timeWindowAllocations) => {
  const result = new Set();

  timeWindowAllocations
    .forEach((timeWindowAllocation) => {
      result.add(JSON.stringify(timeWindowAllocation.timeWindow));
    });

  return [...result].map((cycle) => JSON.parse(cycle));
};

export const processAvailableCycleShifts = (timeWindowAllocations) => {
  const cycleShiftsMap = new Map();
  timeWindowAllocations.forEach((timeWindowAllocation) => {
    const cycle = timeWindowAllocation.timeWindowAttrs[0].cycle;
    const newShiftEntries = [...cycleShiftsMap.get(cycle) || [], timeWindowAllocation.timeWindow];
    cycleShiftsMap.set(cycle, newShiftEntries);
  });
  return cycleShiftsMap;
};

export const processAllocationData = (allocationBoard, associates, availableResourcesIds) => {
  const result = [];

  const associateInfo = {};

  associates.forEach((associate) => {
    if (!associateInfo[associate.id]) {
      associateInfo[associate.id] = new Associate(
        associate.id,
        associate.name,
        '',
        associate.trainedPaths,
        [associate.recommendedPath],
        associate.disallowedPaths,
        0,
        new RingStatus('Blue', 'solid'),
        false,
        associate.id,
      );
    }
  });

  const availableResourceSet = new Set(
    availableResourcesIds.filter((id) => {
      return !!associateInfo[id.toString()];
    })
  );

  allocationBoard.boardEntries.forEach((boardEntry) => {
    const assignments = allocationBoard.resourceAllocations.filter((resourceAllocation) => {
      return (
        resourceAllocation.boardEntity === boardEntry.id && availableResourceSet.has(resourceAllocation.resourceId)
      );
    });

    // removing the associates from the set to add the remaining
    // to the bench

    assignments.forEach((assignment) => {
      availableResourceSet.delete(assignment.resourceId);
    });
    result.push({
      ...boardEntry,
      assignments
    });
  });

  const benchAssignments = [...availableResourceSet].map((resource) => {
    return {
      resourceId: resource,
      boardEntity: PROCESS_PATHS.BENCH,
      allocationAttrs: {
        process: PROCESS_PATHS.BENCH
      }
    };
  });

  result.push({
    id: PROCESS_PATHS.BENCH,
    title: 'Bench',
    type: PROCESS_PATHS.BENCH,
    assignments: benchAssignments,
    processPathEntries: []
  });

  // result.push({
  //   id: PROCESS_PATHS.FIVE_S,
  //   title: '5S',
  //   type: PROCESS_PATHS.FIVE_S,
  //   assignments: [],
  //   processPathEntries: []
  // });

  return {
    processInfo: result,
    associateInfo,
    boardEntries: allocationBoard.boardEntries
  };
};

export const processAssociates = (data, recommendations) => {
  const processedAssociates = data.map((item) => {
    const paths = Object.keys(item.processPathAttributeMap).sort();
    const recommendation = recommendations.find((rec) => rec.resource.resourceId === item.resource.resourceId);

    const disallowedPaths = [];
    const trainedPaths = [];

    paths.forEach((path) => {
      if (item.processPathAttributeMap[path].eligibility) {
        if (item.processPathAttributeMap[path].eligibility.ineligibleCriteria) {
          if (
            item.processPathAttributeMap[path].eligibility.ineligibleCriteria.filter(
              (c) => c.reason !== 'TRAINING_INCOMPLETE'
            ).length > 0
          ) {
            disallowedPaths.push(path);
          }
        }

        if (
          item.processPathAttributeMap[path].eligibility.trainingProfile &&
          item.processPathAttributeMap[path].eligibility.trainingProfile.completedTraining
        ) {
          trainedPaths.push(path);
        }
      }
    });

    return {
      name: nameFormatter(item.resource.resourceName, getRegion()),
      id: item.resource.resourceId,
      disallowedPaths,
      riskRanking: item.riskRanking,
      trainedPaths,
      recommendedPath: recommendation && recommendation.recommendedProcess
    };
  });

  return processedAssociates.sort(alphabeticalSort('name'));
};

export const getTimeWindowAllocationKey = (timeWindowAllocation) => {
  const phase = timeWindowAllocation.timeWindowAttrs[0].phase;
  const cycle = timeWindowAllocation.timeWindowAttrs[0].cycle;
  const start = timeWindowAllocation.timeWindow.start;
  const end = timeWindowAllocation.timeWindow.end;
  return `${cycle}.${phase}.${start}.${end}`;
};

export const prepareModelForBackend = (fullModel, allocationBoards) => {
  const result = cloneDeep(fullModel);

  Object.keys(allocationBoards).forEach((boardKey) => {
    const [cycle, phase, start, end] = boardKey.split('.');
    const timeWindowAllocationIndex = fullModel.output.resourceAllocationOutput.timeWindowAllocations.findIndex(
      (twa) => {
        return (
          twa.timeWindowAttrs[0].phase === phase &&
          twa.timeWindowAttrs[0].cycle === cycle &&
          twa.timeWindow.start === start &&
          twa.timeWindow.end === end
        );
      }
    );

    const assignments = getUpdatedProcessInfoWithAssociatesToFiveSProcessPath(
      allocationBoards[boardKey].processInfo,
      allocationBoards[boardKey].associateInfo
    )
      .filter((process) => process.id !== PROCESS_PATHS.BENCH)
      .reduce((acc, boardEntry) => {
        return acc.concat(boardEntry.assignments);
      }, []);

    const newAllocationBoard = {
      ...fullModel.output.resourceAllocationOutput.timeWindowAllocations[timeWindowAllocationIndex].allocationBoard,
      boardEntries: allocationBoards[boardKey].boardEntries,
      resourceAllocations: assignments
    };

    result.output.resourceAllocationOutput.timeWindowAllocations[timeWindowAllocationIndex].allocationBoard =
      newAllocationBoard;

    result.input.sortLocationReservations = allocationBoards[boardKey].clusterDefinitions;
  });

  return result;
};
