import {
  getAPIGatewayDataFromParent,
  getAuthorizationHeaderFromParent,
  getFeatureStatus,
  getShiftPlanSettings, isBosonProxyAllowed, isMidwayAuth
} from '../handler/parentDataHandler';
import { publishCount, publishTime, METRIC_PROCESS } from './metricUtil';
import {getIsTest, getStationCode, getTabLastSelected, promiseWithTimeout} from './networkUtil';
import { PLANS } from './constants';
import { callApiGateway} from "./apiGatewayAccessor";

const getAuthorizationHeaderWithAccessCheck = async () => {
  try {
    const [bosonProxyResponse, midwayAuthResponse, authorizationHeader] = await Promise.all([
      isBosonProxyAllowed(),
      isMidwayAuth(),
      getAuthorizationHeaderFromParent()
    ]);

    console.log('Authorization Status Check :', {
      isBosonProxyAllowed: bosonProxyResponse.isBosonProxyAllowed,
      midwayAuth: midwayAuthResponse.midwayAuth,
      timestamp: new Date().toISOString()
    });

    // Check if conditions are met
    const isAllowed = !bosonProxyResponse.isBosonProxyAllowed && midwayAuthResponse.midwayAuth;
    return {
      isAllowed,
      authorizationHeader: isAllowed ? authorizationHeader || null : null
    };

  } catch (error) {
    console.error('Error during access check or authorization:', {
      error: error instanceof Error ? {
        name: error.name,
        message: error.message,
        stack: error.stack
      } : error,
      timestamp: new Date().toISOString()
    });

    return {
      isAllowed: false,
      authorizationHeader: null
    };
  }
};

// Wrapped version with timeout
const getAuthorizationHeaderWithAccessCheckAndTimeout = () =>
    promiseWithTimeout(
        getAuthorizationHeaderWithAccessCheck(),
        1000, // 1 seconds timeout
        'Authorization check timed out'
    );


let apis = {
  callAPI: (request, setAsyncError, metricName, forceReturn) => {
    const startTime = Date.now();
    return new Promise((resolve) => {
      getAPIGatewayDataFromParent(request)
        .then((result) => {
          if (result.status === 200) {
            resolve(result.data);
            publishTime(METRIC_PROCESS, metricName, startTime);
            publishCount(METRIC_PROCESS, metricName);
          } else {
            if (!forceReturn) {
              setAsyncError(`Result status: ${result.status}`);
            } else {
              resolve();
            }
            publishCount(METRIC_PROCESS, metricName + 'ERROR');
          }
        })
        .catch((err) => {
          setAsyncError(err.toString());
          publishCount(METRIC_PROCESS, metricName + 'ERROR');
        });
    });
  },
  callFeaturesAPI: (features, setAsyncError) => {
    return new Promise((resolve) => {
      getFeatureStatus(features)
        .then((result) => {
          if (result.status === 200) {
            resolve(result.data);
          } else {
            resolve();
          }
        })
        .catch((err) => {
          setAsyncError(err.toString());
        });
    });
  },
  GET_ALL_PLANS: (req, setAsyncError) => {
    const requestParams = {
      nodeId: [req.query.nodeId],
      ofdDate: [req.query.date]
    };
    if (getIsTest()) {
      if (!!getTabLastSelected() && getTabLastSelected() === PLANS.DYNAMIC) {
        requestParams['planType'] = ['INTRA_DAY'];
      } else {
        requestParams['queryDevPlanVersions'] = [`${getIsTest()}`];
      }
    }
    const request = {
      resourcePath: '/shift-plans',
      httpMethod: 'GET',
      processName: 'shiftPlanManagementService',
      requestParams
    };
    return apis.callAPI(request, setAsyncError, 'getAllPlans');
  },

  GET_ALL_PLANS_IN_RANGE: (req, setAsyncError) => {
    const requestParams = {
      nodeId: [req.query.nodeId],
      startDate: [req.query.startDate],
      endDate: [req.query.endDate]
    };
    if (getIsTest()) {
      requestParams['queryDevPlanVersions'] = [`${getIsTest()}`];
    }
    const request = {
      resourcePath: '/shift-plans-in-range',
      httpMethod: 'GET',
      processName: 'shiftPlanManagementService',
      requestParams
    };
    return apis.callAPI(request, setAsyncError, 'getAllPlans');
  },
  CREATE_PLAN: (req, setAsyncError) => {
    const request = {
      resourcePath: '/shift-plans',
      httpMethod: 'POST',
      processName: 'shiftPlanManagementService',
      requestBody: req.body
    };
    return apis.callAPI(request, setAsyncError, 'createPlan');
  },
  GET_PLAN:  async (req, setAsyncError, forceReturn) => {

    const request = {
      resourcePath: '/shift-plans/plan',
      httpMethod: 'GET',
      processName: 'shiftPlanManagementService',
      requestParams: {
        planId: [req.query.planId]
      }
    };

    try {
      const result = await getAuthorizationHeaderWithAccessCheckAndTimeout();

      if (!result) {
        throw new Error('Authorization check returned no result');
      }

      if (result.isAllowed && (typeof result.authorizationHeader !== undefined)) {
        console.log('Access granted. Proceeding with direct API call.');

         request.headers = {
          stationCode:  getStationCode(),
          Authorization: `Bearer ${result.authorizationHeader}`
        };

        return await callApiGateway(request);
      }

      // If not allowed, fall back to regular API call
      return  apis.callAPI(request, setAsyncError, 'getPlan', forceReturn);

    } catch (error) {
      console.error('Error during access check or authorization:', error);
      return apis.callAPI(request, setAsyncError, 'getPlan', forceReturn);
    }

  },
  GET_ADMIN_SETTINGS: (req) => {
    return new Promise((resolve, reject) => {
      getShiftPlanSettings(req)
          .then((result) => {
            if (result.status === 200) {
              resolve(result.data);
            } else {
              resolve();
            }
          })
          .catch((err) => {
            reject(err);
          });
    });
  },
  UPDATE_PLAN_STATUS: (req, setAsyncError, forceReturn) => {
    const request = {
      resourcePath: `/shift-plans/plan/status`,
      httpMethod: 'PUT',
      processName: 'shiftPlanManagementService',
      requestBody: req.body
    };
    return apis.callAPI(request, setAsyncError, 'updatePlanStatus', forceReturn);
  },
  UPDATE_INPUT: (req, setAsyncError, forceReturn) => {
    const request = {
      resourcePath: `/shift-plans/plan/input`,
      httpMethod: 'PUT',
      processName: 'shiftPlanManagementService',
      requestBody: req.body
    };
    return apis.callAPI(request, setAsyncError, 'updatePlanStatus', forceReturn);
  },
  UPDATE_OUTPUT: (req, setAsyncError, forceReturn) => {
    const request = {
      resourcePath: 'shift-plans/plan/output',
      httpMethod: 'PUT',
      processName: 'shiftPlanManagementService',
      requestBody: req.body
    };
    return apis.callAPI(request, setAsyncError, 'updateOutput', forceReturn);
  },
  GET_ADHERENCE: (req, setAsyncError) => {
    const requestParams = {
      nodeId: [req.query.nodeId],
      timeRangeStartInSeconds: [req.query.timeRangeStartInSeconds],
      timeRangeEndInSeconds: [req.query.timeRangeEndInSeconds]
    };
    const request = {
      resourcePath: '/shift-plans/adherence',
      httpMethod: 'GET',
      processName: 'shiftPlanManagementService',
      requestParams
    };
    return apis.callAPI(request, setAsyncError, 'getAdherence');
  },
  ALLOCATION_ENRICHMENT: (req, setAsyncError) => {
    const request = {
      resourcePath: '/shift-plans/enrichment',
      httpMethod: 'GET',
      processName: 'shiftPlanManagementService',
      requestParams: {
        planId: [req.query.planId]
      }
    };
    return apis.callAPI(request, setAsyncError, 'allocationEnrichment');
  },
  LABOR_TRACKING: (req, setAsyncError) => {
    const request = {
      resourcePath: '/shift-plans/plan/labor-tracking',
      httpMethod: 'POST',
      processName: 'shiftPlanManagementService',
      requestBody: req.body
    };
    return apis.callAPI(request, setAsyncError, 'laborTracking');
  }
};

export default apis;