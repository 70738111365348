export const REGIONS = {
    EU: 'eu',
    IN: 'in',
    JP: 'jp',
    NA: 'na'
};

export const DOMAINS = {
    BETA: 'integ',
    GAMMA: 'gamma',
    PROD: 'prod'
};
/**
 * Endpoint configuration for API Gateway endpoints across different regions and domains.
 * Note: Beta stack is only available in JP region. EU stack serves both EU and IN regions.
 * @type {{
 *   [REGIONS.EU]: {[DOMAINS.PROD]: string, [DOMAINS.BETA]: string, [DOMAINS.GAMMA]: string},
 *   [REGIONS.NA]: {[DOMAINS.PROD]: string, [DOMAINS.BETA]: string, [DOMAINS.GAMMA]: string},
 *   [REGIONS.IN]: {[DOMAINS.PROD]: string, [DOMAINS.BETA]: string, [DOMAINS.GAMMA]: string},
 *   [REGIONS.JP]: {[DOMAINS.PROD]: string, [DOMAINS.BETA]: string, [DOMAINS.GAMMA]: string}
 * }}
 */
const endpointConfig = {
    [REGIONS.EU]: {
        [DOMAINS.BETA]: 'https://shqqcfli3i.execute-api.eu-west-1.amazonaws.com/gamma',
        [DOMAINS.GAMMA]: 'https://shqqcfli3i.execute-api.eu-west-1.amazonaws.com/gamma',
        [DOMAINS.PROD]: 'https://msp96tbt7l.execute-api.eu-west-1.amazonaws.com/prod'
    },
    [REGIONS.IN]: {
        [DOMAINS.BETA]: 'https://shqqcfli3i.execute-api.eu-west-1.amazonaws.com/gamma',
        [DOMAINS.GAMMA]: 'https://shqqcfli3i.execute-api.eu-west-1.amazonaws.com/gamma',
        [DOMAINS.PROD]: 'https://msp96tbt7l.execute-api.eu-west-1.amazonaws.com/prod'
    },
    [REGIONS.JP]: {
        [DOMAINS.BETA]: 'https://7unxliuu9k.execute-api.us-west-2.amazonaws.com/beta',
        [DOMAINS.GAMMA]: 'https://3ykb97f6g9.execute-api.us-west-2.amazonaws.com/gamma',
        [DOMAINS.PROD]: 'https://xnjln8c9v2.execute-api.us-west-2.amazonaws.com/prod'
    },
    [REGIONS.NA]: {
        [DOMAINS.BETA]: 'https://ndl848ke1b.execute-api.us-east-1.amazonaws.com/gamma',
        [DOMAINS.GAMMA]: 'https://ndl848ke1b.execute-api.us-east-1.amazonaws.com/gamma',
        [DOMAINS.PROD]: 'https://tgc0u3bwdd.execute-api.us-east-1.amazonaws.com/prod'
    }
};


/**
 * Gets the complete API Gateway URL based on region and domain
 * @param {string} region - The region code (EU, IN, JP, NA)
 * @param {string} domain - The domain environment (gamma, prod)
 * @returns {string} The complete API Gateway URL
 * @throws {Error} If invalid region or domain is provided
 */
export const getApiGatewayUrl = (region, domain) => {
    // Convert inputs to lowercase for consistent comparison
    const normalizedRegion = region.toLowerCase();
    const normalizedDomain = domain.toLowerCase();

    // Validate region
    if (!Object.values(REGIONS).includes(normalizedRegion)) {
        throw new Error(`Invalid region: ${region}. Valid regions are: ${Object.keys(REGIONS).join(', ')}`);
    }

    // Validate domain
    if (!Object.values(DOMAINS).includes(normalizedDomain)) {
        throw new Error(`Invalid domain: ${domain}. Valid domains are: ${Object.keys(DOMAINS).join(', ')}`);
    }

    // Return the corresponding complete URL
    return endpointConfig[normalizedRegion][normalizedDomain];
};

