import {
  CO_USER_MAX_ALLOWED_VALUE,
  CO_USER_MIN_ALLOWED_VALUE,
  EU,
  GAMMA,
  INTEG,
  MASTER,
  PROD,
  SHIFT_PLAN_SUGGESTED_INPUT
} from './constants';
import moment from 'moment';

export const chainWalk = (func, defaultValue) => {
  try {
    const value = func();
    return value === null || value === undefined ? defaultValue : value;
  } catch (e) {
    return defaultValue;
  }
};

export let convertEpochToDatetimeLocal = (epoch, timezone) => {
  return moment.unix(epoch).tz(timezone).format('YYYY-MM-DD[T]HH:mm:SS.[000Z]');
};

export let parseEpochToDateStringWithOptions = (val, formatOptions) => {
  let dateObject = new Date(parseFloat(val) * 1000);
  return dateObject.toLocaleString('en-US', formatOptions);
};

export let parseEpochToDateStringWithTimeZone = (val, timezone) => {
  let dateObject = new Date(parseFloat(val) * 1000);
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
    hourCycle: 'h23',
    timeZone: timezone.toString()
  };
  return dateObject.toLocaleString('en-US', options);
};

export const isValid = (val) => {
  return !(val === null || val === undefined);
};

export const isStringValid = (val) => {
  return isValid(val) && typeof val === 'string';
};

export const isEmpty = (val) => {
  return !isStringValid(val) || val.trim().length === 0;
};

export const planTypeFormat = (planType) => {
  if (planType === 'NEXT_DAY') return 'Next Day Shift';
  else if (planType === 'PRE_SHIFT') return 'Pre Shift';
  else return 'Start Shift';
};

export const convertToFixedDP = (val, dp) => {
  return parseFloat(parseFloat(val).toFixed(isValid(dp) ? dp : 1));
};

export const convertTimeStampToEpoch = (val) => {
  return new Date(val).getTime() / 1000;
};

export const changeDateFormat = (date) => {
  let [y, m, d] = date.split('-');
  return `${m}/${d}/${y}`;
};

export const isDateOld = (date) => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const [year, month, day] = date.split('-');
  const givenDate = new Date(year, month - 1, day);
  return givenDate < today;
};

export const extractMainWorkGroup = (workGroups) => {
  if (!workGroups) {
    return '';
  }
  let mainWorkGroup = workGroups.filter((workGroup) => workGroup.endsWith('(Main Activity)'))[0];
  if (!mainWorkGroup) {
    return '';
  }
  return mainWorkGroup.replace('(Main Activity)', '').trim();
};

export const getWindowDurationInMin = (start, end) => {
  const d1 = new Date(start);
  const d2 = new Date(end);
  let diff = (d2 - d1) / 60000;
  return Math.abs(Math.round(diff));
};

export const isDateSubwindow = (parentStart, parentEnd, childStart, childEnd) => {
  return parentStart <= childStart && childEnd <= parentEnd;
};

export const isDateStringSubwindow = (parentStart, parentEnd, childStart, childEnd) => {
  return isDateSubwindow(new Date(parentStart), new Date(parentEnd), new Date(childStart), new Date(childEnd));
};

export const parseTimeStampToDateString = (val) => {
  let time = val.split('T')[1];
  let [hr, min] = time.split(':');
  return `${hr}:${min}`;
};

export const parseTimeStampToDateStringWith12Hr = (val) => {
  let time = val.split('T')[1];
  let [hr, min] = time.split(':');
  let newHr = hr > 12 ? hr - 12 : hr;
  let m = hr >= 12 ? 'p' : 'a';

  return `${newHr}:${min}${m}`;
};

const zeroPad = (num) => String(num).padStart(2, '0');

export let parseEpochToDateString = (val) => {
  let dateObject = new Date(parseFloat(val) * 1000);
  let day = zeroPad(dateObject.getDate());
  let month = zeroPad(dateObject.getMonth() + 1);
  let year = dateObject.getFullYear();
  let time = dateObject.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  return `${day}/${month}/${year} ${time}`;
};

export let parseEpochToHourMinute = (epochMillis, timeZone) => {
  const date = new Date(epochMillis);
  const formatter = new Intl.DateTimeFormat([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: timeZone
  });
  return formatter.format(date);
};

export let generateDateIncrements = (startDateMillis, endDateMillis, incrementMillis) => {
  const dateIncrements = [startDateMillis];

  for (let i = 0; i <= (endDateMillis - startDateMillis) / incrementMillis; i++) {
    dateIncrements.push(startDateMillis + i * incrementMillis);
  }

  return dateIncrements;
};

/** Fetches list of dates from `curr - numDaysAvailablePast` to `curr + numDaysAvailableFuture` days
 * Both params default to 7 days, totalling a 15 day window. */
export const getAvailableDates = (numDaysAvailablePast = 7, numDaysAvailableFuture = 7) => {
  let res = [];
  let date = new Date();
  date.setDate(date.getDate() - numDaysAvailablePast - 1);
  for (let i = 0; i <= numDaysAvailablePast + numDaysAvailableFuture; i++) {
    date.setDate(date.getDate() + 1);
    res.push(formatDateForAPI(date));
  }
  return res;
};

export const getCurrentEpoch = () => {
  return new Date().getTime() / 1000;
};

const formatDateForAPI = (date) => {
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  return [date.getFullYear(), '-', (mm > 9 ? '' : '0') + mm, '-', (dd > 9 ? '' : '0') + dd].join('');
};

export const getTodaysDate = () => {
  let date = new Date();
  return formatDateForAPI(date);
};

export const isNumberInvalid = (val) => {
  return val === undefined || val === null || val === '' || !isFinite(val);
};

export const isNumberNotInRange = (val, minAllowed, maxAllowed, isUserAuthorisedForShiftPlanOverrides = false) => {
  return isUserAuthorisedForShiftPlanOverrides
    ? val < CO_USER_MIN_ALLOWED_VALUE || val > CO_USER_MAX_ALLOWED_VALUE
    : val < minAllowed || val > maxAllowed;
};

export const moveHardCodedElementsToBeginningOfArray = (elementArray, array) => {
  return elementArray
    .filter((element) => array.includes(element))
    .concat(array.filter((element) => !elementArray.includes(element)));
};

export const isJSONEmpty = (jsonObject) => {
  return (
    jsonObject && // 👈 null and undefined check
    Object.keys(jsonObject).length === 0 &&
    Object.getPrototypeOf(jsonObject) === Object.prototype
  );
};

export const addFifteenMinutesToEpoch = (epoch) => {
  return epoch + 15 * 60;
};

export const removeSecondsFromTimeStamp = (timeStamp) => {
  let [hr, min] = timeStamp.split(':');
  return `${hr}:${min}`;
};

export const alphabeticalSort =
  (key, direction = 'ascending') =>
  (a, b) => {
    const directionMultiplier = 'ascending' === direction ? 1 : -1;
    if ((a[key] === null || a[key] === undefined) && (b[key] === null || b[key] === undefined)) {
      return 0;
    }
    if (a[key] === null || a[key] === undefined) {
      return directionMultiplier;
    }
    if (b[key] === null || b[key] === undefined) {
      return -1 * directionMultiplier;
    }
    return directionMultiplier * (a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1);
  };

export const getRegion = () => {
  const url = window.location.href;

  if (url.includes('eu.')) return 'eu';
  if (url.includes('jp.')) return 'jp';
  if (url.includes('in.')) return 'in';
  return 'na';
};


export const compareDates = (date1, date2) => {
  return date1 < date2;
};

export function getDomain() {
  const url = window.location.href;
  return url.includes(INTEG) ? INTEG : url.includes(GAMMA) ? GAMMA : url.includes(PROD) ? PROD : null;
}

export const isRegionEU = () => {
  return getRegion() === EU;
};

export const isSSDCycle = (cycleName) => {
  if (cycleName === null || cycleName === undefined) {
    return false;
  }
  const regex = /^CYCLE_SD_.$/i;
  return regex.test(cycleName);
};

export const sortList = (list) => {
  return list !== null && list !== undefined ? list.sort() : list;
};
