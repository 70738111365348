// apiGatewayAccessor.js

import { v4 as uuidv4 } from 'uuid';
import {getDomain, getRegion} from "./helpers";
import {getApiGatewayUrl} from "./apiGatewayEndpointHelper";

const ERROR_MAP = Object.freeze({
    400: 'Bad Request - Invalid parameters or payload',
    401: 'Unauthorized - Authentication failed',
    403: 'Forbidden - Insufficient permissions',
    404: 'Resource not found',
    429: 'Too Many Requests - API throttling limit exceeded',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout'
});


const formatQueryParams = (params) => {
    if (!params) return '';

    const queryString = Object.entries(params)
        .filter(([_, value]) => value != null && value.length > 0)
        .map(([key, values]) => {
            // Handle array values
            if (Array.isArray(values)) {
                return values
                    .filter(value => value != null)
                    .map(value => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                    .join('&');
            }
            // Handle single value
            return `${encodeURIComponent(key)}=${encodeURIComponent(values)}`;
        })
        .filter(param => param)
        .join('&');

    return queryString;
};


const handleApiGatewayError = (error, requestId, resourcePath) => {
    const status = error.status || 500;
    const errorMessage = ERROR_MAP[status] || 'Unknown error occurred';

    console.log({
        level: 'error',
        event: 'API_GATEWAY_ERROR',
        requestId,
        path: resourcePath,
        status,
        message: errorMessage,
        timestamp: new Date().toISOString(),
        error: error instanceof Error ? {
            name: error.name,
            message: error.message,
            stack: error.stack
        } : error
    });

};

const callApiGateway = async (request) => {
    const requestId = uuidv4();
    const startTime = performance.now();

    try {
        if (!request.resourcePath || !request.httpMethod) {
            //throw new Error('Missing required parame: resourcePath or httpMethod');
        }

        const stage =  getDomain() || 'prod';
        const region = getRegion();

        const baseUrl = getApiGatewayUrl(region, stage)

        let urlString = `${baseUrl}${request.resourcePath}`;

        // Handle query parameters
        if (request.requestParams) {
            const queryString = formatQueryParams(request.requestParams);
            if (queryString) {
                urlString += `${urlString.includes('?') ? '&' : '?'}${queryString}`;
            }
        }

        const url = new URL(urlString);

        if (request.requestParams) {
            url.search = new URLSearchParams(
                Object.fromEntries(
                    Object.entries(request.requestParams)
                        .filter(([_, value]) => value != null)
                )
            ).toString();
        }

        // Default headers that are CORS-safe
        const defaultHeaders = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };



        const fetchOptions = {
            method: request.httpMethod,
            headers: {
                ...request.headers,
                ...defaultHeaders
            },
            credentials: 'omit',
            mode: 'cors'
        };

        if (request.body && ['POST', 'PUT', 'PATCH'].includes(request.httpMethod)) {
            fetchOptions.body = JSON.stringify(request.body);
        }

        const logContext = {
            requestId,
            url: url.toString(),
            path: request.resourcePath,
            method: request.httpMethod,
            headers: fetchOptions.headers,
            timestamp: new Date().toISOString()
        };

        console.log({ event: 'API_REQUEST', ...logContext });

        const response = await fetch(url.toString(), fetchOptions);
        const duration = performance.now() - startTime;

        console.log({
            event: 'API_RESPONSE',
            ...logContext,
            duration,
            status: response.status,
            ok: response.ok
        });

        const data = await response.json();

        if (!response.ok) {
            handleApiGatewayError(
                { status: response.status, message: data.message },
                requestId,
                request.resourcePath
            );
            return Promise.reject(response.status, data.message);
        }

        console.debug({
            event: 'API_SUCCESS',
            ...logContext,
            duration
        });

        return data;

    } catch (error) {
        handleApiGatewayError(error, requestId, request.resourcePath);
    }
};

// Export both as named and default export
export { callApiGateway };
export default callApiGateway;
